import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { FC } from "react";
import PageOutline from "../PageOutline";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";

interface CommonErrorPageProps {
  errorContent: JSX.Element;
  showFaqBtn?: boolean;
  showSupportBtn?: boolean;
}

const LINKS_URL = "https://links.moreapp.com/";

const CommonErrorPage: FC<CommonErrorPageProps> = ({ errorContent, showFaqBtn = true, showSupportBtn = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navButtons = (
    <>
      {showFaqBtn && (
        <IconAndTextButton
          variant="transparentBrand"
          icon="ArrowRightIcon"
          iconAlign="right"
          label={t("GOTO_FAQ_BUTTON")}
          onClick={() => window.location.replace(`${LINKS_URL}help-center`)}
        />
      )}
      {showSupportBtn && (
        <IconAndTextButton
          variant="transparentBrand"
          icon="ArrowRightIcon"
          iconAlign="right"
          label={t("SUPPORT_REQUEST_BUTTON")}
          onClick={() => window.location.replace(`${LINKS_URL}contact-support`)}
        />
      )}
    </>
  );

  const header = <img className="h-10" src="/assets/logo-inverted.png" alt={t("LOGO_ALT")} />;

  const content = (
    <div className="lg:p-4">
      {errorContent}
      <div className="-mb-1 mt-4 flex flex-col items-end">
        <IconAndTextButton
          block
          variant="primary"
          icon="HomeIcon"
          iconAlign="right"
          label={t("GOTO_HOME_PAGE_BUTTON")}
          onClick={() => navigate(`/`)}
        />
        <div className="mt-6 flex flex-col items-end gap-6 lg:hidden">{navButtons}</div>
      </div>
    </div>
  );

  const footer = <div className="hidden max-w-full justify-between align-middle lg:flex">{navButtons}</div>;

  return <PageOutline header={header} content={content} footer={footer} />;
};

export default CommonErrorPage;
