import { useInfiniteQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useNavigate, useOutletContext } from "react-router";
import Content from "../components/Content";
import EmptyContent from "../components/EmptyContent";
import MenuPage from "../components/MenuPage";
import SearchDrawer from "../components/SearchDrawer";
import SentSubmissionDetailDrawer from "../components/SentSubmissionDetailDrawer";
import { useMoreAppClient } from "../context/MoreAppContext";
import useAuth from "../hooks/useAuth";
import useDeviceOrientation from "../hooks/useDeviceOrientation";
import useDrawer from "../hooks/useDrawer";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Paginated } from "../types/Paginated";
import { SubmissionSummary } from "../types/Submission";
import { NON_DESCRIPTIVE_IMAGE } from "../utils/a11yUtil";
import { toHumanReadableDate } from "../utils/dateUtil";
import { fromPlatformIcon } from "../utils/iconUtil";
import { NavigationPageOutletContext } from "./NavigationPage";
import OfflineContent from "./errorpages/OfflineContent";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";
import { NavItem, NavItemMeta } from "../storybook/components/NavItem/NavItem";
import { IconName } from "../storybook/components/Icon/Icon";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import { useSubmissionResend } from "../hooks/useSubmissionResend";
import { resolveTheme } from "../storybook/themes";

const PAGE_SIZE = 20;
const REFETCH_INTERVAL = 5_000;

const SentPage: FC = () => {
  const { t } = useTranslation();
  const { customerId } = useAuth();
  const client = useMoreAppClient();
  const { isOnline } = useOnlineStatus();
  const [search, setSearch] = useDrawer("search");
  const { setHeaderProps, setShowWorkspaceHeader } = useOutletContext<NavigationPageOutletContext>();
  const getSentSubmissionSummaries = async (pageParam = 0, sizeParam = 10): Promise<Paginated<SubmissionSummary>> => {
    const response = await client!.get<Paginated<SubmissionSummary>>(
      `/api/v1.0/client/customers/${customerId}/submissions`,
      {
        params: {
          page: pageParam,
          size: sizeParam,
        },
      },
    );
    return response.data;
  };
  const {
    data,
    fetchNextPage,
    isFetching: isFetchingSent,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ["submissions.sent", customerId],
    ({ pageParam = 0 }) => getSentSubmissionSummaries(pageParam, PAGE_SIZE),
    {
      getNextPageParam: (lastPage) => (lastPage && !lastPage.last && lastPage.number + 1) || undefined,
      getPreviousPageParam: (firstPage) => (firstPage && !firstPage?.first && firstPage.number - 1) || undefined,
      enabled: !!customerId,
      cacheTime: 0,
      refetchInterval: (value) =>
        value?.pages.flatMap((x) => x?.content).some((x) => x?.status === "UNPROCESSED") ? REFETCH_INTERVAL : false,
    },
  );

  const { result: submissions, isFetching } = useLocalSubmissions((collection) =>
    collection.find().where("customerId").eq(customerId).where("submittedAt").ne(null).sort("-updatedAt"),
  );
  const navigate = useNavigate();
  const [latchInView, setLatchInView] = useState(false);
  const { ref: sentRef, inView } = useInView();
  const sent = data ? data?.pages.flatMap((d) => (d && d?.content) || []) : [];

  const [activeSubmission, setActiveSubmission] = useState<SubmissionSummary | undefined>();
  const [detailDrawer, setDetailDrawer] = useDrawer("detail");
  const { landscapeIndent } = useDeviceOrientation(false);

  useSubmissionResend();

  useEffect(() => {
    setShowWorkspaceHeader(true);
    setHeaderProps({
      defaultTitle: t("SENT_PAGE_TITLE"),
      actions: [
        {
          "aria-label": t("SEARCH"),
          kind: "icon",
          icon: "SearchIcon",
          onClick: () => setSearch(true),
        },
      ],
    });
  }, [setHeaderProps, setShowWorkspaceHeader, t]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refetch({ refetchPage: (_page, index) => index === 0 });
  }, [submissions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latchInView && !inView) {
      setLatchInView(false);
    }
  }, [latchInView, inView]);

  useEffect(() => {
    if (!latchInView && inView && !isFetchingSent && !isFetchingNextPage) {
      setLatchInView(true);
      fetchNextPage();
    }
  }, [inView, isFetchingSent, isFetchingNextPage, fetchNextPage, latchInView]);

  const finalizedSubmissions = (): JSX.Element => {
    const title = t("SENDING_IN_PROGRESS", { count: submissions.length });
    return (
      <section aria-label={title}>
        <Title as="h2" size="xs" className="mt-6 pl-4">
          {title}
        </Title>
        {submissions?.map((submission) => (
          <NavItem
            className="py-1"
            key={submission.id}
            icon={{
              name: fromPlatformIcon(submission.form.icon),
              theme: resolveTheme(submission.form.iconColor),
              showSubIcon: !!submission.task,
            }}
            label={submission.form.name}
            btnAriaLabel={t("VIEW_SUBMISSION_DETAILS")}
            description={submission.description ?? submission.form.description}
            meta={[
              {
                icon: "CalendarIcon",
                label: toHumanReadableDate(submission.submittedAt ?? submission.updatedAt, true),
              },
              ...(submission.status === "draft"
                ? ([{ icon: "UploadIcon", label: t("PENDING_FILE_UPLOADS") }] as NavItemMeta[])
                : []),
            ]}
            onClick={() => navigate(`/submissions/${submission.id}`)}
          />
        ))}
      </section>
    );
  };

  const sentSubmissions = (): JSX.Element => {
    const title = t("SENT_FORMS");
    return (
      <section aria-label={title}>
        <Title as="h2" size="xs" className="mt-6 pl-4">
          {title}
        </Title>
        {sent?.map((submission) => {
          const processed = submission.status === "PROCESSED";
          return (
            <NavItem
              className="py-1"
              key={submission.id}
              icon={{
                name: fromPlatformIcon(submission.formIcon || "ios-paper-outline"),
                theme: resolveTheme(submission.formIconColor),
              }}
              label={submission.formName}
              description={submission.description || submission.formDescription}
              btnAriaLabel={t("VIEW_SUBMISSION_DETAILS")}
              onBtnClick={() => {
                setActiveSubmission(submission);
                setDetailDrawer(true);
              }}
              meta={[
                {
                  icon: "CalendarIcon",
                  label: toHumanReadableDate(new Date(submission.registrationDate), true),
                },
                ...(!processed
                  ? [
                      {
                        icon: "ExclamationCircleIcon" as IconName,
                        label: t("UNPROCESSED_SUBMISSION"),
                      },
                    ]
                  : []),
              ]}
              onClick={() => processed && navigate(`/sent/${submission.id}`)}
              disabled={!processed}
            />
          );
        })}
        {hasNextPage && !isFetchingNextPage && <div ref={sentRef} />}
        <div className="flex flex-col items-center justify-center p-6">
          {(isFetchingSent || (hasNextPage && isFetchingNextPage)) && (
            <>
              <Spinner className="mb-2" />
              <Text color="medium" size="sm">
                {t("RETRIEVING_SENT_SUBMISSIONS")}
              </Text>
            </>
          )}
        </div>
      </section>
    );
  };

  const isEmptyState = !isFetching && !isFetchingSent && submissions.length === 0 && sent.length === 0;

  const emptyState = isOnline ? (
    <EmptyContent
      title={t("SENT_EMPTY_TITLE")}
      description={t("SENT_EMPTY_DESCRIPTION")}
      imgAlt={NON_DESCRIPTIVE_IMAGE}
      imgSrc="assets/empty.svg"
    />
  ) : (
    <OfflineContent centered className="mx-6" />
  );

  return (
    <MenuPage showTabBar>
      <Content padding={false} className={landscapeIndent}>
        <SearchDrawer open={search} onClose={() => setSearch(false)} initialCategory="ALL" />
        <SentSubmissionDetailDrawer
          submissionSummary={activeSubmission}
          onClose={() => {
            setDetailDrawer(false);
          }}
          open={detailDrawer}
        />
        {isEmptyState ? (
          emptyState
        ) : (
          <>
            {submissions && submissions.length > 0 && finalizedSubmissions()}
            {sentSubmissions()}
          </>
        )}
      </Content>
    </MenuPage>
  );
};

export default SentPage;
