import { useTranslation } from "react-i18next";
import { FC } from "react";
import PageOutline from "../PageOutline";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";

interface InitializationErrorPageProps {
  errorContent: JSX.Element;
}

const HELP_URL = "https://help.moreapp.com/";

/**
 * Duplicate of CommonErrorPage, but without the navigation
 */
const InitializationErrorPage: FC<InitializationErrorPageProps> = ({ errorContent }) => {
  const { t } = useTranslation();

  const navButtons = (
    <>
      <IconAndTextButton
        variant="transparentBrand"
        icon="ArrowRightIcon"
        iconAlign="right"
        label={t("GOTO_FAQ_BUTTON")}
        onClick={() => window.location.replace(HELP_URL)}
      />
      <IconAndTextButton
        variant="transparentBrand"
        icon="ArrowRightIcon"
        iconAlign="right"
        label={t("SUPPORT_REQUEST_BUTTON")}
        onClick={() => window.location.replace(`${HELP_URL}en/support/tickets/new`)}
      />
    </>
  );

  const header = (
    <img className="mx-auto my-6 h-9 lg:mb-6 lg:mt-7 lg:h-full" src="assets/logo-banner.svg" alt={t("LOGO_ALT")} />
  );

  const content = (
    <div className="lg:p-4">
      {errorContent}
      <div className="-mb-1 flex flex-col items-end">
        <div className="mt-6 flex flex-col items-end gap-6 lg:hidden">{navButtons}</div>
      </div>
    </div>
  );

  const footer = <div className="hidden max-w-full justify-between align-middle lg:flex">{navButtons}</div>;

  return <PageOutline header={header} content={content} footer={footer} />;
};

export default InitializationErrorPage;
